<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <AnswerForm :task="task" v-if="task" />
  </main>
</template>

<script>
import Task from '../../../store/models/Task';

import AnswerForm from '../../../components/student/task/AnswerForm.vue';

export default {
  components: { AnswerForm },

  computed: {
    task() {
      return Task.query().withAll().find(this.$route.params.id);
    },
  },

  metaInfo() {
    return { title: this.task && this.task.subject.name };
  },

  beforeMount() {
    this.$store.dispatch('tasks/fetch', {
      filter: { id: this.$route.params.id },
    });

    this.$store.commit('info/setBreadcrumb', [
      { path: '/siswa/tugas', label: 'Tugas' },
    ]);
  },
};
</script>
