<template>
  <form class="bg-white rounded-8" @submit.prevent="handleSubmit">
    <div class="d-flex align-items-center justify px-24 py-16 border-bottom">
      <h2 class="fs-20 me-auto mb-0">{{ task.title }}</h2>
      <button
        type="submit"
        class="btn btn-primary"
        :class="{ 'is-loading': isSubmitting }"
        :disabled="isSubmitting || task.isPastDue"
      >
        Simpan
      </button>
    </div>

    <RichTextContent
      class="p-24 border-bottom"
      :content="task.content"
      v-if="task.content"
    />

    <div class="p-24 border-bottom" v-if="task.file">
      <a class="text-decoration-none" :href="task.fileURL" target="_blank"
        ><CloudDownloadIcon class="me-8" />File tugas</a
      >
    </div>

    <div class="p-24">
      <TextareaField
        label="Jawaban"
        required
        v-model="input.text"
        :attrs="{ disabled: task.isPastDue }"
        :error="errors.text"
      />
    </div>

    <div class="p-24 border-top" v-if="input.comment">
      <label>Komentar Guru:</label>
      <MarkdownContent :content="input.comment" />
    </div>
  </form>
</template>

<script>
import get from 'lodash/get';
import taskRepository from '../../../api/repositories/taskRepository';
import Task from '../../../store/models/Task';

import MarkdownContent from '../../content/MarkdownContent.vue';
import RichTextContent from '../../content/RichTextContent.vue';
import TextareaField from '../../form/TextareaField.vue';

import CloudDownloadIcon from 'bootstrap-icons/icons/cloud-download.svg';

export default {
  components: {
    MarkdownContent,
    RichTextContent,
    TextareaField,
    CloudDownloadIcon,
  },

  props: ['task'],

  data() {
    return { errors: {}, input: this.getInitialInput(), isSubmitting: false };
  },

  methods: {
    async handleSubmit() {
      try {
        this.errors = {};
        this.isSubmitting = true;

        await taskRepository
          .answer(this.task.id, this.input)
          .then(({ data }) => Task.insert({ data: [data.data] }));
        this.$alert.success('Jawaban berhasil dikirim');
      } catch (error) {
        this.errors = get(error, 'response.data.error.errors', {});
        this.$alert.requestError(error);
      } finally {
        this.isSubmitting = false;
      }
    },

    getInitialInput() {
      const answer = get(this.task, 'answers.[0]');
      const input = answer ? answer.$toJson() : {};
      return input;
    },
  },

  watch: {
    task() {
      this.input = this.getInitialInput();
    },
  },
};
</script>
